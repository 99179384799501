.account-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff7e6;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    &__title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #333;
    }
  
    &__form {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  
    &__field {
      display: flex;
      flex-direction: column;
    }
  
    &__label {
      font-size: 14px;
      margin-bottom: 5px;
      color: #555;
    }
  
    &__input {
      padding: 10px;
      font-size: 14px;
      border: 1px solid #ddd;
      border-radius: 5px;
      outline: none;
      transition: border-color 0.3s;
  
      &:focus {
        border-color: #ffcc00;
      }
    }
  
    &__submit {
      background-color: #ffcc00;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #e6b800;
      }
    }
  }
  