@import "../../styles/mixins";

.userAccounts {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff7e6;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @include respond-to(big) {
    padding: 0.4rem;
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }

  &__info {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
  }

  &__form {
    display: flex;
    gap: 10px;
    // flex-direction: column;
    margin-bottom: 20px;
  }

  &__img {
    width: 25px;
    height: 25px;
    margin-left: .5rem;
  }

  &__input {
    // flex: 3;
    input {
      border-radius: 5px;
      padding: 10px;
    }
  }

  &__addButton {
    // flex: 1;
    background-color: #ffcc00;
    color: #000;
    font-weight: bold;
    border-radius: 8px;
    padding: 10px;
    &:hover {
      background-color: #e6b800;
    }
  }

  &__table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 10px;
      text-align: left;
      border: 1px solid #ddd;
    }

    th {
      background-color: #ffe9c6;
      font-weight: bold;
    }

    td {
      background-color: #fff;
    }
  }
}
