@import "../../styles/mixins";

.header {
  display: flex;
  flex-direction: column;

  &__coins-list {
    background-color: black;
    height: 1.8rem;
    color: white;
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    overflow: hidden;
    white-space: nowrap;

    // Добавляем дважды список для бесшовной анимации
    .header__coin {
      padding: 0 1rem;
      display: inline-block;
    }

    &__content {
      display: flex;
      animation: marquee 40s linear infinite;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    align-self: center;
    width: 100%;
    @include respond-to(big) {
      max-width: 90%;
    }
  }

  &__title {
    font-family: "MuseoModerno", Arial, Helvetica, sans-serif;
    font-size: 48px;
    margin: 3rem 3rem 3rem 0;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    transition: all ease-in-out 0.2s;
    &:hover {
      color: #3b3b3b;
    }
    @include respond-to(large) {
      margin-left: 1rem;
      margin-right: 0;
    }
    @include respond-to(small) {
      text-align: start;
      font-size: 40px;
      margin-bottom: 1rem;
    }
  }

  &__link {
    border: none;
    background-color: white;
    font-size: 16px;
    margin-right: 0;
    padding: 0.5rem;
    color: black;
    text-decoration: none;
    border-radius: 6px;
    border: 1px solid #8080801a;
    // visibility: hidden;
    @include respond-to(small) {
      margin-right: 0;
    }
    &.active {
      background-color: #ffcc00;
      color: #000;
    }
  }
  &__link:hover {
    background-color: #8080801a;
    cursor: pointer;
  }
  &__link:last-of-type {
    margin: 0;
  }
  &__links {
    display: flex;
    gap: 20px;
    @include respond-to(small) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      margin-right: 1rem;
      margin-top: 1rem;
    }
  }
  &__logout {
    border: none;
    cursor: pointer;
  }
  
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
