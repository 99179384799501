@import "../../styles/mixins";

.footer {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  @include respond-to(big) {
    padding-top: 3rem;
  }
  &__container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    width: 800px;
    padding-bottom: 4rem;
    @include respond-to(big) {
        flex-direction: column;
        width: 100%;
        align-items: center;
      }
  }
  &__li {
    color: white;
    font-size: 24px;
    text-decoration: none;
    @include respond-to(big) {
        margin-top: 1rem;
      }
      @include respond-to(small) {
        font-size: 20px;
      }
  }
  &__rights {
    color: #838383;
    font-size: 14px;
    padding-bottom: 3rem;
    margin-left: 1rem;
  }
}
