.partner-exchanges {
    margin: 20px 0;
  
    &__title {
      font-size: 1.3rem;
      margin: 1rem;
    }
  
    &__table {
      background-color: #fff4cc; /* Светлый желтоватый фон */
      border-radius: 12px;
      padding: 16px;
  
      .table-header {
        padding: 12px;
        border-radius: 8px;
        font-weight: 600;
        margin-bottom: 8px;
  
        .header-cell {
          /* Дополнительные стили для заголовков */
        }
      }
  
      .table-body {

        row-gap: 8px; /* Отступы между строками */
  
        .table-row {
          background-color: #ffe9a3;
          padding: 12px;
          border-radius: 8px;
  
          &.no-data {
            text-align: center;
          }
  
          .cell {
            /* Дополнительные стили для ячеек */
          }
        }
      }
    }
  }
  