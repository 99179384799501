.partner-withdraw {
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;

  .partner-withdraw__box {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
}
