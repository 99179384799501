@import "../../styles/mixins";

.admin-orders {
  padding: 20px;
  @include respond-to(big) {
    padding: 0 !important;
  }
  &__filter {
    display: flex;
    align-items: start;
    margin-bottom: 1rem;
    gap: 1rem;
    @include respond-to(big) {
      flex-direction: column;
      margin-left: 1rem;
    }
  }
  h2 {
    margin-bottom: 20px;
    @include respond-to(big) {
      font-size: 1rem;
      margin-left: 1rem;
    }
  }

  .filter {
    margin-bottom: 20px;
  }

  .orders-table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #ddd;
      padding: 12px 15px;
      text-align: left;
      @include respond-to(big) {
        padding: 6px 7px;
      }
    }
    th {
      background-color: #ffb300;
      color: #fff;
    }
    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  }
}
