@import "../../styles/mixins";

.partner-account {
    margin: 20px 0;
  
    &__title {
      font-size: 1.5rem;
      margin-bottom: 16px;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      @include respond-to(big) {
        flex-direction: column;
      }
    }
  
    &__table {
      background-color: #fff4cc; /* Светлый желтоватый фон, как в макете */
      border-radius: 12px;
      padding: 16px;
      display: grid;
      grid-template-columns: 1fr; /* Каждая запись — отдельная строка */
      row-gap: 12px;
      width: fit-content;
      height: fit-content;
  
      .stats-row {
        display: grid;
        grid-template-columns: 1fr 1fr; 
        background-color: #ffe9a3; /* Немного более тёмный оттенок */
        padding: 8px;
        border-radius: 8px;
        gap: 1rem;
        .stats-label {
          font-weight: 500;
        }
  
        .stats-value {
          font-weight: 700;
  
          .download-button {
            background-color: #ffd54f;
            border: none;
            border-radius: 4px;
            padding: 6px 12px;
            cursor: pointer;
            font-weight: 600;
            transition: background-color 0.2s ease;
  
            &:hover {
              background-color: #ffc107;
            }
          }
        }
      }
    }
  }
  