.operations-container {
  margin: 20px 0;

  .operations-title {
    font-size: 1.5rem;
    margin-bottom: 16px;
  }

  // Статистика (верхний бокс)
  .operations-stats {
    background-color: #fff4cc; /* светлый желтоватый фон */
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr; // По аналогии с таблицей 2 колонки
    row-gap: 12px;
    column-gap: 12px;
    max-width: fit-content;

    .stats-row {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      background-color: #ffe9a3; /* чуть более тёмный оттенок */
      border-radius: 8px;
      gap: 1rem;
      align-items: center;

      .stats-label {
        font-weight: 500;
      }
      .stats-value {
        font-weight: 700;
      }

      &.stats-download {
        text-align: right;
      }
    }

    .download-button {
      background-color: #ffd54f;
      border: none;
      border-radius: 4px;
      padding: 6px 12px;
      cursor: pointer;
      font-weight: 600;
      &:hover {
        background-color: #ffc107;
      }
    }
  }

  // Таблица операций
  .operations-table {
    background-color: #fffbea;
    border-radius: 12px;
    padding: 16px;

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        background-color: #ffe9a3;
        border-radius: 8px;

        th {
          text-align: left;
          padding: 12px;
          font-weight: 600;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #ffc107;
          &:last-child {
            border-bottom: none;
          }

          td {
            padding: 10px;
          }

          .no-data {
            text-align: center;
            padding: 20px 0;
          }
        }
      }
    }
  }
}
